import { useState, useEffect } from "react";
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/drcat.png";
import prayerImg from "../assets/img/jc_wealth_prayer.png";
import tencommandmentsImg from "../assets/img/tencommandments.png";
import originImg from "../assets/img/toly_and_jc.png";
import lastsupperImg from "../assets/img/lastsupper.png";
import vid from "../assets/img/DrCat.mp4";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

import navIcon1 from '../assets/img/nav-icon1 black.svg';
import navIcon2 from '../assets/img/telegramsvg.svg';
import navIcon3 from '../assets/img/dexscreener.svg';
import navIcon4 from '../assets/img/solscan.svg';
import { HashLink } from 'react-router-hash-link';
import {
  BrowserRouter as Router
} from "react-router-dom";

import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/memes/badluck.jpg";
import projImg2 from "../assets/img/memes/meme4_button.jpg";
import projImg3 from "../assets/img/memes/thebibleofjesuscat.jpg";
import projImg4 from "../assets/img/memes/meme5_excuseme.png";
import projImg5 from "../assets/img/memes/meme3_intheroom.jpg";
import projImg6 from "../assets/img/memes/bro.jpg";
import projImg7 from "../assets/img/memes/meme1_anakin.jpg";
import projImg8 from "../assets/img/memes/meme2_theydontknow.jpg";
import projImg9 from "../assets/img/memes/dominos.jpg";
import projImg10 from "../assets/img/memes/papi.PNG";
import projImg11 from "../assets/img/memes/praisehim.jpg";


import on_the_mount from "../assets/img/drcatmeme.png";

export const Banner = () => {
  
  const memes = [
    {
      imgUrl: projImg1,
    },
    {
      imgUrl: projImg2,
    },
    {
      imgUrl: projImg3,
    },
    {
      imgUrl: projImg4,
    },
    {
      imgUrl: projImg5,
    },
    {
      imgUrl: projImg6,
    },
    {
      imgUrl: projImg7,
    },
    {
      imgUrl: projImg8,
    },
    {
      imgUrl: projImg9,
    },
    {
      imgUrl: projImg10,
    },
    {
      imgUrl: projImg11,
    }
  ];

  return (
    <section className="banner" id="home">
      <div className="animate__animated animate__zoomIn">

              <div className="pitch_section">
                <h1><br/>Dr Cat</h1>
     
                <div id="frame_wrapper">
                <div className="frame">
                  <img src={headerImg} alt="Header Img"/>
                </div>
                <div className="pitch">

                <p>
                  Dr Cat ($DRCAT) graduated top of his class in medical school. He is the world's leading liquidation doctor. He treats crypto degens that have blown up their accounts on too much leverage.


                </p>
                </div>
                </div>
              <div className="CA">
              <p>CA: XXXXXXXXXXXXXXXXXXXXXXXXXXXX</p>
              </div>
              <div className="social-icon">
                    <a href="https://twitter.com/drcatSOL"><img src={navIcon1} alt="" /></a>
                    <a href="https://t.me/DrCatSOL"><img src={navIcon2} alt="" /></a>
              </div>
            </div>
            </div>

            <div class="marquee">
            <div class="scroll text4">
    <div>
      $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span>
      $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span>
    
    </div>
    <div>
      $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span>
      $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span> $DRCAT <span>$DRCAT </span>
    
    </div>
  </div>
  </div>

            <div className="prophecy_section">
                <h1><br/>Dr Cat's Story</h1>
                <video className="remoteVideo" controls onclick="this.play();arguments[0].preventDefault();">
                    <source src={vid} type="video/mp4"/>
                  </video>
            </div>

<div className="on_the_mount">
<h1><br/></h1>
  <img src={on_the_mount} alt="Header Img"/>
</div>

    </section>
  )
}
